import axios from 'axios';

const BASE_URL = process.env.VUE_APP_BACKEND_API;

const loginConfig = {
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
};
export const LoginAPIInstance = axios.create(loginConfig);

const secondConfig = {
    baseURL: 'https://api.oraclus.com',
    headers: {
        'Content-Type': 'application/json'
    }
};
export const SecondAPIInstance = axios.create(secondConfig);

const chainsConfig = {
    baseURL: 'https://leap.oraclus.com/v1',
    headers: {
        'Content-Type': 'application/json',
        'Requested-From': 'frontend'
    }
};
export const ChainsAPIInstance = axios.create(chainsConfig);

const cexubConfig = {
    baseURL: 'https://api-cexub.oraclus.com/api/',
    headers: {
        'Content-Type': 'application/json',
        'Requested-From': 'frontend'
    }
};
export const CexubAPIInstance = axios.create(cexubConfig);

const defaultConfig = {
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
};
export const DefaultAPIInstance = axios.create(defaultConfig);

DefaultAPIInstance.interceptors.request.use(
    config => {
        const accessToken = localStorage.getItem('token')
        if (accessToken) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config
    },
    error => Promise.reject(error),
)

DefaultAPIInstance.interceptors.response.use(
    response => response,
    async error => {
        const originalConfig = error.config;
        if (error.response) {

            if(error.response.status === 401 && !originalConfig._retry){
                originalConfig._retry = true;
                try {
                  const refreshToken = localStorage.getItem('refresh_token');
                  const rs = await LoginAPIInstance.post("/auth/token/refresh/", {"refresh":refreshToken});
                  localStorage.setItem('token', rs.data.access);
                  return DefaultAPIInstance(originalConfig);
                } catch (_error) {
                  localStorage.removeItem('token');
                  localStorage.removeItem('refresh_token');
                  delete DefaultAPIInstance.defaults.headers['authorization'];
                  console.log(_error);
                  window.location = '/login';
                  // return Promise.reject(_error);
                }
            }

        }
        return Promise.reject(error);
    }
)

import { createRouter, createWebHistory } from 'vue-router'
// import PublicLayout from '../views/layouts/PublicLayout.vue';
import CabinetLayout from '../views/layouts/CabinetLayout.vue';

// eslint-disable-next-line no-prototype-builtins
// const isAuthorized = localStorage.hasOwnProperty('token');
// const authGuard = function (to, from, next){
//   if(!isAuthorized) next({ name: 'Login' });
//   else next()
// }

const routes = [
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () => import('../views/default-pages/Login.vue')
  // },
  {
    path: '/',
    name: 'LandingPage',
    component: () => import('../views/default-pages/LandingPage')
  },
  {path: "/:pathMatch(.*)*", component: () => import('../views/default-pages/NotFoundPage.vue')},
  {
    path: '/cabinet',
    component: CabinetLayout,
    children: [
        {
          path: '',
          name: 'MainPage',
          component: () => import('../views/cabinet-pages/MainPage'),
          meta:{
            activeNav: '1'
          }
        },
        {
          path: 'to-other-cex',
          name: 'ToOtherCex',
          component: () => import('../views/cabinet-pages/first-hop/ToOtherCex'),
          meta:{
            activeNav: '2'
          }
        },
        {
          path: 'to-other-wallets',
          name: 'ToOtherWallets',
          component: () => import('../views/cabinet-pages/first-hop/ToOtherWallets'),
          meta:{
            activeNav: '3'
          }
        },
        {
          path: 'from-wallets-to-cex',
          name: 'FromWalletsToCex',
          component: () => import('../views/cabinet-pages/first-hop/FromWalletsToCex'),
          meta:{
            activeNav: '4'
          }
        },
        {
          path: 'from-cex-to-users',
          name: 'FromCexToUsers',
          component: () => import('../views/cabinet-pages/second-hop/FromCexToUsers'),
          meta:{
            activeNav: '5'
          }
        },
        {
          path: 'from-users-to-cex',
          name: 'FromUsersToCex',
          component: () => import('../views/cabinet-pages/second-hop/FromUsersToCex'),
          meta:{
            activeNav: '6'
          }
        },
    ],
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  document.title = to.meta?.title ?? 'Oraclus | User Behavior';
  let link = document.createElement('link');
  link.setAttribute('rel', 'canonical');
  link.setAttribute('href', location.protocol + '//' + location.host + to.fullPath);
  document.head.appendChild(link);
})

export default router

<template>
  <el-header>
    <el-container>
      <el-row :gutter="30" align="middle">
        <el-col :xs="{span:12}" :sm="{span:6}" :md="{span: 4}" class="zIndex2">
          <a href="/" class="logo"><img src="@/assets/img/landing/logo.png" alt=""></a>
        </el-col>
<!--        <el-col :xs="{span: 0}" :sm="{span: 7}" :md="{span: 7}">-->
<!--        </el-col>-->
<!--        <el-col :xs="{span:12}" :sm="{span:11}" :md="{span:13}" class="text-right zIndex2">-->
<!--          <a href="/cabinet" class="defaultButton mini hiddenForMobile" v-if="userInfo">-->
<!--            <span>{{ this.overflowText(userInfo.email) }}</span>-->
<!--          </a>-->
<!--          <a href="/login" class="defaultButton mini" v-else>-->
<!--            <span>Login</span>-->
<!--          </a>-->
<!--        </el-col>-->
      </el-row>
    </el-container>
  </el-header>
</template>

<script>
export default {
  methods:{
    overflowText(text){
      if(text.length > 10){
        text = text.substring(0, 10) + '...';
      }
      return text;
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.get_user_info;
    }
  }
}
</script>

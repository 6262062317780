import {CexubAPIInstance} from "@/plugins/axios";
export const GET_REPORTS = "GET_REPORTS";
export const GET_OPTIONS = "GET_OPTIONS";

const state = {

}

const actions = {
    [GET_REPORTS]:async (store, filter)=>{
        const { data }  = await CexubAPIInstance({url:'/reports/?exchange='+filter.chain,method:'GET'});
        return data;
    },
    [GET_OPTIONS]:async ()=>{
        const { data }  = await CexubAPIInstance({url:'/reports/options/',method:'GET'});
        return data;
    },
}

const mutations = {

}

const getters = {

}
export default {
    state,
    actions,
    mutations,
    getters
}

<template>
  <router-view/>
</template>

<script>
export default {
  created() {
    //fix el-select
    let fixElSelect = function () {
      document.querySelectorAll('.el-select.customSelectRemote:hover').forEach(() => {
        let elInput = document.querySelector('.el-select.customSelectRemote:hover input[readonly]');
        if (elInput) {
          elInput.readOnly = false;
          elInput.blur();
          elInput.focus();
        }
      });
    };
    document.addEventListener("focusin", fixElSelect);
    document.addEventListener("click", fixElSelect);
    document.addEventListener("touchstart", fixElSelect);
  }
}
</script>
<template>
  <div class="asideInBlock">
    <p class="title" @click="this.menuOpened=!this.menuOpened"><i class="bi bi-list" v-if="this.is_mobile"></i>Navigation Bar</p>
    <ul class="menu" :class="this.menuOpened?'opened':''">
      <li>
        <a class="firstItem" :class="this.activeNav === '1'?'active':''" href="/cabinet"><span>Summary</span></a>
      </li>
      <p class="menuHead">First Hop</p>
      <li>
        <a :class="this.activeNav === '2'?'active':''" href="/cabinet/to-other-cex"><span>To other CEX</span></a>
      </li>
      <li>
        <a :class="this.activeNav === '3'?'active':''" href="/cabinet/to-other-wallets"><span>To other wallets</span></a>
      </li>
      <li>
        <a :class="this.activeNav === '4'?'active':''" class="disabled"><span>From wallets to CEX (Soon)</span></a>
      </li>
      <p class="menuHead">Our on-chain users behavioural analytics</p>
      <li>
        <a :class="this.activeNav === '5'?'active':''" class="disabled"><span>From Users to CEX (Soon)</span></a>
      </li>
      <li>
        <a :class="this.activeNav === '6'?'active':''" class="disabled"><span>From CEX to Users (Soon)</span></a>
      </li>
    </ul>
  </div>
</template>
<script>
import {ON_LOGOUT} from '@/store/modules/AuthModule';

export default {
  data:()=>({
    activeNav: '',
    is_mobile: false,
    menuOpened: false
  }),
  watch: {
    '$route'() {
      this.activeNav = this.$route.meta.activeNav;
    }
  },
  methods:{
    logoutFunc() {
      this.$store.dispatch(ON_LOGOUT);
    }
  },
  created() {
    this.activeNav = this.$route.meta.activeNav;
    if(screen.width <= 678){
      this.is_mobile = true;
    }
  },
}
</script>
